import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  initialize () {
    this.currentTime         = new Date().getTime()
    this.hiddenPeriodDays    = 14
    this.hiddenPeriod        = this.hiddenPeriodDays * (24 * 60 * 60 * 1000)

    this.skippedCookieKey    = 'skipped_telegram_popup_at'
    this.subscribedCookieKey = 'is_visited_telegram_popup'

    this.classNames = {
      hidden: 'popup_hidden',
    }

    this._handleShow()
  }

  handleClickButtonClose () {
    this._close()
    this._setCookie(this.skippedCookieKey, this.currentTime, this.hiddenPeriodDays)
  }

  handleClickButtonAccept () {
    this._close()
    this._setCookie(this.skippedCookieKey, true, 365)
  }

  // -----------------------------------------------------------------------------------------------

  _handleShow () {
    if (this._isSubscribed() || this._isSkipped()) return

    this._show()
  }

  _show () {
    this.element.classList.remove(this.classNames.hidden)
  }

  _close () {
    this.element.classList.add(this.classNames.hidden)
  }

  _isSubscribed () {
    const cookie = Cookies.get(this.subscribedCookieKey)

    return Boolean(cookie)
  }

  _isSkipped () {
    const cookie = Cookies.get(this.skippedCookieKey)

    return Boolean(cookie)
  }

  _setCookie (name, value, lifetime) {
    Cookies.set(name, value, { path: '/;', expires: lifetime })
  }
}
