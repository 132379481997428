import CommentFormController from './comment-form-controller'

export default class extends CommentFormController {
  connect () {
    this.commentContainer = this.element.closest('.comment')
    this.commentBody = this.commentContainer.querySelector('.comment__body')

    this._focusTextField()
  }

  handleCancelButtonClick () {
    this._cancelEdit()
  }

  // -----------------------------------------------------------------------------------------------

  _handleFormSuccessResponse (response) {
    this.commentBody.remove()
    this._appendComment(response)
    this._hideForm()
  }

  _appendComment (response) {
    this.commentContainer.insertAdjacentHTML('beforeend', response)
  }

  _cancelEdit () {
    this.commentBody.classList.remove('d-none')
    this._hideForm()
  }

  _hideForm () {
    this.element.closest('.comments-form').remove()
  }

  // Focus and move cursor to end.
  _focusTextField () {
    const val = this.textFieldTarget.value
    this.textFieldTarget.focus()
    this.textFieldTarget.value = ''
    this.textFieldTarget.value = val
  }
}
