// Rails unobtrusive scripting adapter
import Rails from '@rails/ujs'

// ..
import '../lib/device'
import '../lib/swiper'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/modal'

// Stimulus controllers
import 'controllers'

// -------------------------------------------------------------------------------------------------

Rails.start()
