import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static classes = [ "hidden" ]

  initialize () {
    this.visitedCookieKey = 'cookies_terms_accepted'

    this.classNames = {
      hidden: 'cookie-dialog_hidden',
    }

    this._handleShow()
  }

  handleClose () {
    this._close()
    this._setCookie()
  }

  //-----------------------------------------------------------------------------------------------

  _handleShow () {
    if (this._isVisited()) return

    setTimeout(() => this._show(), 1500)
  }

  _show () {
    this.element.classList.remove(this.classNames.hidden)
  }

  _close () {
    this.element.classList.add(this.classNames.hidden)
  }

  _isVisited () {
    return !!Cookies.get(this.visitedCookieKey)
  }

  _setCookie () {
    Cookies.set(this.visitedCookieKey, true, { path: '/;' })
  }
}
