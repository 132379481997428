import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  handleClick (e) {
    e.preventDefault()

    let alternateLocale = document.head.querySelector('meta[name=alternate-locale]').content;
    let alternatePageUrl = document.head.querySelector(`link[hreflang=${alternateLocale}-UA`).href;

    this._setCookies("locale", alternateLocale);
    window.location = alternatePageUrl
  }

  _setCookies (name, value) {
    let domain = document.head.querySelector('meta[name=host]').content;
    Cookies.set(name, value, { domain: domain, expires: 365 })
  }
}
