import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['textField', 'submitButton', 'errorMessage']

  initialize () {
    this.form = this.element;
    this.formSubmitable = false

    this._listenTextFieldHotkeys()
  }

  // connect () {}
  // disconnect () { }

  handleTextFieldInput () {
    let valueLength = this.textFieldTarget.value.replace(/\s+/g, '').length
    this.formSubmitable = valueLength > 0
    this._setFormSubmitting()
  }

  handleFormPreSubmit () {
    this.formSubmitable = false

    this._setFormSubmitting()
  }

  handleFormSuccess (event) {
    let [data, status, xhr] = event.detail
    this._resetFormState()
    this._handleFormSuccessResponse(xhr.response)
  }

  handleFormError (event) {
    let [data, status, xhr] = event.detail
    this._handleFormErrorResponse(xhr.response)
  }

  // -----------------------------------------------------------------------------------------------

  _listenTextFieldHotkeys () {
    this.textFieldTarget.addEventListener('keydown', (event) => {
      if (event.keyCode == 13 && event.metaKey) {
        this.handleTextFieldInput()

        if (this.formSubmitable) {
          Rails.fire(this.form, 'submit')
        }
      }
    })
  }

  _handleFormErrorResponse (response) {
    this.errorMessageTarget.innerHTML = `
      <div class="message message_error mb-3">Что-то пошло не так. Попробуйте позже</div>
    `
  }

  _resetFormState () {
    this.formSubmitable = false
    this._clearTextField()
    this._clearErrorMessage()
    this._setFormSubmitting()
  }

  _clearErrorMessage () {
    try {
      this.errorMessageTarget.removeChild(this.errorMessageTarget.firstChild)
    } catch (e) {}
  }

  _clearTextField () {
    this.textFieldTarget.value = ''
  }

  _setFormSubmitting () {
    this.submitButtonTarget.disabled = !this.formSubmitable
  }
}
