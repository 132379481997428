import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { getRecaptchaToken, setRecaptchaToken } from '../lib/recaptcha'

export default class extends Controller {
  static targets = [ 'submitButton', 'notificationMessage', 'textarea', 'recaptchaInput' ]

  initialize () {
    this.form            = this.element
    this.modalId         = '#insightModal'

    this.formSubmitable  = false

    this.recaptchaAction = 'send_insight'

    this._listenCloseModal()
    this._listenOpenModal()
  }

  async handleFormSubmit (event) {
    event.preventDefault()

    this.formSubmitable = false
    this._setFormSubmitting()

    const token = await getRecaptchaToken(this.recaptchaAction)
    setRecaptchaToken(this.recaptchaInputTarget, token)

    this._sendForm()
  }

  handleTextInput (event) {
    const valueLength = event.target.value.replace(/\s+/g, '').length

    this.formSubmitable = valueLength > 0
    this._setFormSubmitting()
  }

  // -----------------------------------------------------------------------------------------------

  _sendForm () {
    const data = new FormData(this.form)

    Rails.ajax({
      url: this.form.action,
      type: 'POST',
      data,
      success: () => {
        this._handleFormSuccessResponse()
      },
      error: () => {
        this._handleFormErrorResponse()
      }
    })
  }

  _listenCloseModal () {
    $(this.modalId).on('hidden.bs.modal', this._clearNotificationMessage.bind(this))
  }

  _listenOpenModal () {
    $(this.modalId).on('shown.bs.modal', () => {
      this.textareaTarget.focus()
    })
  }

  _handleFormSuccessResponse () {
    this.notificationMessageTarget.innerHTML = `
      <div class='alert alert-success mb-3 rounded-sm' role='alert'>Сообщение отправлено редакции. Спасибо!</div>
    `

    this._resetFormState()
  }

  _handleFormErrorResponse () {
    this.notificationMessageTarget.innerHTML = `
      <div class='alert alert-danger mb-3 rounded-sm' role='alert'>Что-то пошло не так. Попробуйте позже</div>
    `
  }

  _resetFormState () {
    this.formSubmitable = false
    this._setFormSubmitting()

    this.form.reset()
  }

  _clearNotificationMessage () {
    try {
      this.notificationMessageTarget.innerHTML = ''
    } catch (e) {
      console.error(e)
    }
  }

  _setFormSubmitting () {
    this.submitButtonTarget.disabled = !this.formSubmitable
  }
}
